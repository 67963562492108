import React, { useEffect } from "react"


import Layout from "../components/layout"
import SEO from "../components/seo"
import Navigation from "../components/nav"


class Twitch extends React.Component {
    scriptLoaded() {
        var height = window.innerHeight - 60;
        new window.Twitch.Embed("twitch-embed", {
            width: '100%',
            height: height,
            channel: "BarristerGaming"
          });
      }

    componentDidMount() {

        const script = document.createElement("script");
        script.src = "https://embed.twitch.tv/embed/v1.js";
        script.async = true;
        script.onload = () => this.scriptLoaded();
      
        document.body.appendChild(script);
    }


    render() {
        return (
      <Layout location={this.props.location}>
        <SEO
          title="Home"
          keywords={[`blog`, `law`, `barristers`, `tajwar`]}
        />


        <div id="twitch-embed"></div>
      </Layout>
        )
    }
}

export default Twitch
